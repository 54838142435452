<template>
  <div>
    <KTCodePreview title="Trạng thái đồng bộ với CMS">
      <template v-slot:preview>
        <div>
          <b-table
            :items="syncConfigList"
            :fields="syncConfigListFields"
            bordered
            hover
            :busy="isLoading"
            :per-page="0"
          >
            <template v-slot:table-busy>
              <vcl-table :speed="5" :animate="true" :columns="20"></vcl-table>
            </template>
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
          </b-table>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { VclTable } from 'vue-content-loading';
import ApiService from '../../../core/services/api.service';

export default {
  data() {
    return {
      syncConfigList: null,
      syncConfigListFields: [
        {
          key: 'index',
          label: 'STT',
          thStyle: { textAlign: 'center', width: '1%' },
          tdClass: 'text-center align-middle',
        },
        {
          key: 'key',
          label: 'Key module',
          thStyle: { textAlign: 'center', width: '4%' },
          tdClass: 'text-left align-middle',
        },
        {
          key: 'name',
          label: 'Tên module',
          thStyle: { textAlign: 'center', width: '8%' },
          tdClass: 'text-left align-middle',
        },
        {
          key: 'time',
          label: 'Thời gian',
          thStyle: { textAlign: 'center', width: '3%' },
          tdClass: 'text-center align-middle',
        },
      ],
      isLoading: true,
    };
  },
  components: {
    KTCodePreview,
    VclTable,
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      ApiService.get(`/cms-sync/config`).then((response) => {
        // console.log('this.syncConfigList', response.data.data);
        const data = response.data.data;
        this.syncConfigList = data;

        console.log('this.syncConfigList', this.syncConfigList);

        this.isLoading = false;
      });
    },
  },
};
</script>
